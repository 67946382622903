import { action, makeObservable, observable } from "mobx";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  BACKEND_URL,
  LOGIN_PATH,
  LOGOUT_PATH,
  PASS_RECOVER,
  PASSWORD_CONFIRM,
  USER_PATH
} from "shared/config/backendPaths";
import { EventEmitter } from "shared/lib/EventEmitter";
import { IAuthResponseData, ILogoutResponse, IUserData, IUserRequestData } from "../types";
import { UserModel } from "entities/User/UserModel";
import { IPassResponseData } from "../types/IPassResponseData";
import i18n from "../../../shared/lib/i18n";

type EntityListEvents = {
  "authUserDataReceived": (data: IAuthResponseData) => void;
  "authSuccess": () => void;
  "invalidCredential": () => void;
  "logoutSuccess": (data: ILogoutResponse) => void;
  "userCheckSuccess": (data: IUserData) => void;
  "recoverRequested": (message: string) => void;
  "passwordConfirmed": (message: string) => void;
};

export class AuthStore extends EventEmitter<EntityListEvents> {
  private static shared: AuthStore | null;

  isAuth: boolean = false;

  isLoading: boolean = false;

  currentUser: UserModel | null = null;

  isFormActive: boolean;

  needRecover: boolean = false;

  constructor() {
    super();

    this.isFormActive = false;

    this.on("userCheckSuccess", (userData: IUserData) => {
      if (userData) {
        this.makeAuth(userData);
      }
    });

    makeObservable(this, {
      isFormActive: observable,
      isAuth: observable,
      isLoading: observable,
      needRecover: observable,

      openForm: action,
      closeForm: action,
      setIsAuth: action,
      setIsNotAuth: action,
      setIsLoading: action,
      makeAuth: action,
      signOut: action,
      toggleNeedCover: action
    });
  }

  static getShared(): AuthStore {
    if (this.shared) {
      return this.shared;
    }

    this.shared = new AuthStore();
    return this.shared;
  }

  /**
   * Отправка нового пароля
   */
  async sendNewPassword({ password, password_confirmation }: { password: string; password_confirmation: string }) {
    const token = localStorage.getItem("passToken");

    if (!token) return;

    try {
      const result: AxiosResponse<{ message: string; redirect: string; user: IUserData }> = await axios
        .get(`${PASSWORD_CONFIRM}/?token=${token}&password=${password}&password_confirmation=${password_confirmation}`);

      /* При успешном обновлении пароля сервер возвращает 201 */
      if (result && result.status === 201) {
        this.emit("passwordConfirmed", result.data.message);
        return result.data;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log(`${i18n.t("passChangeFailure")}: ${error}`);
      }
    }
  }

  /**
   * Метод для восставноления пароля.
   * Отправляет пользовательский email.
   * @param userData
   */
  async requestRecover(userData: { email: string }) {
    try {
      const result: AxiosResponse<IPassResponseData> = await axios.post(PASS_RECOVER, userData);

      if (result && result.status === 200) {
        this.emit("recoverRequested", result.data.message);

        if (this.isFormActive) {
          this.closeForm();
        }
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log(`${i18n.t("passChangeFailureError")}: ${error}`);
      }
    }
  }

    async login({ email, password }: IUserRequestData) {
      try {
        const result: AxiosResponse<IAuthResponseData> = await axios.get(`${BACKEND_URL}${LOGIN_PATH}?email=${email}&password=${password}`);

        if (result.status === 200) {
          this.emit("authUserDataReceived", result.data);
          const { user, token } = result.data;

          if (user.email_verified_at !== null) {
            this.makeAuth(user, token);
          }

          return result;
        }
      } catch (error) {
        this.emit("invalidCredential");
        this.signOut();
        console.warn("error", error);
      }
    }

    async logout() {
      const token = localStorage.getItem("authToken");
      if (!token) return;

      try {
        const result: AxiosResponse<ILogoutResponse> = await axios.post(BACKEND_URL + LOGOUT_PATH, {}, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });

        if (result.status === 200) {{
          this.emit("logoutSuccess", result.data);
          this.signOut();
        }}
      } catch (error) {
        console.warn(i18n.t("logoutFailure"));
        this.signOut();
      }
    }

  setIsAuth() {
    this.isAuth = true;
    this.emit("authSuccess");
  }

  setIsNotAuth() {
    this.isAuth = false;
  }

  setIsLoading(state: boolean) {
    this.isLoading = state;
  }

  setUser(user: UserModel) {
    this.currentUser = UserModel.init(user);
  }

  makeAuth(data: IUserData, token?: string) {
    const user = {
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      typeUser: data.type_user,
      emailVerifiedAt: data.email_verified_at
    };

    if (!user) return;

    this.setUser(user);
    this.setIsAuth();
    localStorage.setItem("currentUser",  JSON.stringify(user));

    if (token) this.setToken(token);
  }

  signOut() {
    this.isAuth = false;
    this.currentUser = null;

    localStorage.removeItem("authToken");
    localStorage.removeItem("currentUser");
  }

  setToken(token: string) {
    const prevToken = localStorage.getItem("authToken");
    if (prevToken) {
      localStorage.removeItem("authToken");
    }

    localStorage.setItem("authToken", token);
  }

  async userCheck() {
    this.setIsLoading(true);
    const token = localStorage.getItem("authToken");

    if (!token) {
      this.setIsLoading(false);
      return;
    }

    try {
      const result: AxiosResponse<IUserData> = await axios.get(BACKEND_URL + USER_PATH, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });

      if (result.status === 200) {
        this.emit("userCheckSuccess", result.data as IUserData);
        this.setIsLoading(false);
      }

      this.setIsLoading(false);
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
          localStorage.removeItem("authToken");
          this.setIsLoading(false);
          console.warn(i18n.t("loginFailure"));
        }
    }
    this.setIsLoading(false);
  };

  openForm() {
    this.isFormActive = true;
  }

  closeForm() {
    if (this.needRecover) {
      this.toggleNeedCover();
    }
    this.isFormActive = false;
  }

  toggleNeedCover() {
    this.needRecover = !this.needRecover;
  }
}