import "./SidebarBtn.css";

import { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { CircleButton } from "shared/ui/CircleButton";
import { rmdIcons } from "shared/icons";
import {useTranslation} from "react-i18next";
import {StationSwitchStore} from "../../../../features/SwitchStation";

type Props = {
    action: () => void;
    isVisible: boolean;
    isAuth: boolean;
};

export const SidebarBtn = observer(({ action, isVisible, isAuth }: Props) => {
  const { t } = useTranslation();
  const stationSwitchStore = StationSwitchStore.getShared();
  return (
    isVisible
      ? <CircleButton
        action={action}
        className="sidebar-btn"
        children={rmdIcons.sidebar}
        hint={isAuth || stationSwitchStore.currentStation?.icon === "deep" || stationSwitchStore.currentStation?.icon === "dnb" ? t("sidebarMenu") : t("authRequired")}
      />
      : <Fragment />
  )
});