import { CustomStationModel, StationModel } from "entities/Station";
import { PlayerControlStore } from "../store/PlayerControlStore";
import {AppMediaStore, AppStore} from "../../../app";
import {StationSwitchStore} from "../../SwitchStation";
import {SongModel} from "../../../entities/Song";
import {NowPlayingModel} from "../../../entities/NowPlaying";


export async function runPlayAfterSwitch(
  model: StationModel | CustomStationModel,
  currentStation: StationModel | CustomStationModel | null
) {
  const playerControlStore = PlayerControlStore.getShared();
  const stationSwitchStore = StationSwitchStore.getShared();
  const {appMediaStore} = AppStore.getShared();
  const {
    nowPlayingStore
  } = appMediaStore;

  if (model.id === currentStation?.id) {
    if (model.name === "personal") {
      const songModel = new SongModel(stationSwitchStore.myTracks[0]);
      const songUrl = await songModel.onDownloadSong({
        songPath: stationSwitchStore.myTracks[0]?.path,
        storageId: stationSwitchStore.myTracks[0]?.storageLocationId
      });
      playerControlStore.togglePlay(!playerControlStore.isPlaying, songUrl?.replace(`https://${process.env.REACT_APP_BACKEND_DOMAIN}`, ""));
      const model = new NowPlayingModel({ duration: parseInt(stationSwitchStore.myTracks[0].length), elapsed: 0, remaining: 0, song: stationSwitchStore.myTracks[0] })
      nowPlayingStore.setNowPlayingData(model)
    } else {
      playerControlStore.togglePlay(!playerControlStore.isPlaying, model.audioUrl);
    }
  } else {
    if (model.name === "personal") {
      const songModel = new SongModel(stationSwitchStore.myTracks[0]);
      const songUrl = await songModel.onDownloadSong({
        songPath: stationSwitchStore.myTracks[0]?.path,
        storageId: stationSwitchStore.myTracks[0]?.storageLocationId
      });
      playerControlStore.togglePlay(true, songUrl?.replace(`https://${process.env.REACT_APP_BACKEND_DOMAIN}`, ""));
      const model = new NowPlayingModel({ duration: parseInt(stationSwitchStore.myTracks[0].length), elapsed: 0, remaining: 0, song: stationSwitchStore.myTracks[0] })
      nowPlayingStore.setNowPlayingData(model)
    } else {
      playerControlStore.togglePlay(true, model.audioUrl);
    }
  }
}