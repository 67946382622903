import { action, makeObservable, observable } from "mobx";
import axios, { AxiosError, AxiosResponse } from "axios";
import { SongHistoryModel } from "entities/SongHistory";
import { ISongFavoriteResponseConfig, SongFavoriteModel } from "entities/Song";
import { BACKEND_URL, STATION_PATH } from "shared/config/backendPaths";
import i18n from "shared/lib/i18n";

export class SongHistoryStore {
    topTracksList: SongHistoryModel[] = [];

    songHistory: SongFavoriteModel[] = [];

    constructor() {
        makeObservable(this, {
            topTracksList: observable,
            songHistory: observable,
            setTopTracks: action,
            setSongHistory: action,
        })
    }

    setTopTracks(items: SongHistoryModel[]) {
        this.topTracksList = items;
    }

    setSongHistory(items: SongFavoriteModel[]) {
        this.songHistory = items;
    }

    async fetchSongHistory(currentStation: string) {
        try {
            const result: AxiosResponse<ISongFavoriteResponseConfig[]> = await axios.get(BACKEND_URL + STATION_PATH + `/${currentStation}/history`);
            if (result && result.data) {
                const historyData = result.data.map(item => new SongFavoriteModel(item));
                this.setSongHistory(historyData);
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                console.log(`${i18n.t("historyError")}`, error);
            }
        }

    }
}