import './Song.css';

import {observer} from "mobx-react-lite";
import {FavouriteModel} from "entities/Favourite";

import {SongFavoriteModel} from "../../model/SongFavoriteModel";
import {ISongActionsMap} from "../SongControls/types";
import {SongControls} from "../SongControls/SongControls";

import TRACK_PREVIEW_PATH from "./track_preview_default.svg";
import {AppStore} from "../../../../app";
import axios from "axios";
import {NowPlayingModel} from "../../../NowPlaying";
import {useTranslation} from "react-i18next";

type Props = {
  song: SongFavoriteModel;
  likedSongs: FavouriteModel[];
  handleNonAuthAction: () => void;
  isAuth: boolean;
  listType: string;
};

export const Song = observer(({
                                song,
                                likedSongs,
                                handleNonAuthAction,
                                isAuth,
                                listType
                              }: Props) => {
  const { t } = useTranslation();
  const getStationId = () => likedSongs
    .find(song => song.id === song.id)?.stationId;
  const {appMediaStore} = AppStore.getShared();
  const {
    stationSwitchStore, playerControlStore, nowPlayingStore
  } = appMediaStore;
  const handleLike = async (model: SongFavoriteModel) => {
    if (!isAuth) {
      handleNonAuthAction();
      return;
    }
    await model.onToggleLike({
      songId: model.songId,
      stationId: getStationId()
    });
  };

  const handleDownload = async (model: SongFavoriteModel) => {
    if (!isAuth) {
      handleNonAuthAction();
      return;
    }

    const resultUrl = await model.onDownloadSong({
      songPath: model.path,
      storageId: model.storageLocationId
    });
    if (resultUrl) {
      await axios({
        url: resultUrl,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        const filename = resultUrl.split("/").slice(-1)[0]
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    }
  };

  const handleStart = async (model: SongFavoriteModel) => {
    if (!isAuth) {
      handleNonAuthAction();
      return;
    }
    const songUrl = await model.onDownloadSong({
      songPath: model.path,
      storageId: model.storageLocationId
    });
    playerControlStore.audioRef.crossOrigin = "anonymous";
    const nowPlaying = new NowPlayingModel({ duration: parseInt(model.length), elapsed: 0, remaining: 0, song: model })
    nowPlayingStore.setNowPlayingData(nowPlaying);
    playerControlStore.togglePlay(true, songUrl?.replace(`https://${process.env.REACT_APP_BACKEND_DOMAIN}`, ""));
    const personalStation = stationSwitchStore.stationList.find(el => el.name === "personal");
    if (personalStation) {
      stationSwitchStore.setCurrent(personalStation);
      stationSwitchStore.setListType(listType);
    }
    await stationSwitchStore.getMyTracks();
  };

  const songActionsMap: ISongActionsMap = {
    play: {
      action: handleStart,
      checkIsActive: () => false,
      isAvailable: isAuth
    },
    like: {
      action: handleLike,
      checkIsActive: () => !!likedSongs.find(item => (item.songId === song.songId)),
      isAvailable: isAuth,
      itemCount: () => song.likesCount,
      actionName: "like",
    },
    download: {
      action: handleDownload,
      checkIsActive: () => false,
      isAvailable: isAuth,
    }
  };

  return (
    <li className="song">
      <div className="song__meta">
        <img
          className="song__preview-pic"
          src={song.art ?? TRACK_PREVIEW_PATH}
          alt="album pic"
        />
        <div className="song__description">
          <p className="song__title">{song.title ?? t("noData")}</p>
          <p className="song__artist">{song.artist ?? t("noData")}</p>
        </div>
      </div>
      <SongControls
        model={song}
        controlsMap={songActionsMap}
        isAuth={isAuth}
      />
    </li>
  );
});