import axios, { AxiosError, AxiosResponse } from "axios";
import { FavouriteStore } from "entities/Favourite";
import { FavouriteModelConfig } from "entities/Favourite/types";
import { BACKEND_URL, LIKE_PATH, TRACKS_PATH } from "shared/config/backendPaths";
import { getCurrentToken } from "shared/lib/getCurrentToken";
import i18n from "../../shared/lib/i18n";

interface IToggleLikeConfig {
  songId: string;
  stationId?: number
}

export async function toggleLike(config: IToggleLikeConfig) {
  const favoriteStore = FavouriteStore.getShared();
  const token = getCurrentToken();
  if (!token) return;

  try {
    const { songId, stationId } = config;
    const requestData = new FormData();
    requestData.append("song_id", songId);
    if (stationId) {
      requestData.append("station_id", String(stationId));
    }
    const result: AxiosResponse<{
      likes: FavouriteModelConfig[];
      code: number;
      message: string;
    }> = await axios.post(BACKEND_URL + TRACKS_PATH + LIKE_PATH, requestData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (result && result.status === 200) {
      favoriteStore.emit("songHasBeenLiked", result.data.likes);
      return result;
    }
    return result;
  } catch (err: unknown) {
    if (err instanceof AxiosError) {
      console.log(`${i18n.t("likeError")}: ${err}`);
      return new Error(`${i18n.t("likeError")}: ${err}`);
    }
  }
}