import './Tariffs.css';

import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { AppStore } from "app";
import { HeaderAccount } from "widgets/HeaderAccount";
import { Tabs } from 'widgets/Tabs';
import { Player } from 'widgets/Player';
import { Plans } from "features/Plans";
import { RmdLogo } from "shared/ui/RmdLogo";
import { pagePaths } from 'shared/config';

import { RmdPage } from '../RmdPage';

export const Tariffs = observer(() => {
  const navigate = useNavigate();
  const appStore = AppStore.getShared();
  const { authorizationProcess: { authStore } } = AppStore.getShared();

  return (
    <RmdPage
      headerLeft={""}
      headerMiddle={<RmdLogo onClick={() => navigate(pagePaths.HOME_ROUTE)}/>}
      headerRight={
        <HeaderAccount authProcess={appStore.authorizationProcess} />
      }
      body={
        <main className="tariffs-page">
          <Tabs />
          <Plans />
        </main>
      }
      footer={
        <Player
          handleNonAuthAction={() => authStore.openForm()}
        />
      }
    />
  );
});