import "./Player.css";

import { Fragment, useRef } from "react";
import { observer } from "mobx-react-lite";
import { AppStore, useScreenMode } from "app";
import { VolumeBarStore } from "features/VolumeBar";
import { Equalizer } from "features/Equalizer";
import { TimeLine } from "features/TimeLine";
import { useStoreFactory } from "shared/hook/useStoreFactory";

import { PlayerControls } from "./ui/PlayerControls/PlayerControls";
import { VolumeBar } from "./ui/VolumeBar/VolumeBar";
import { PlayerSongControls } from "./PlayerSongControls/PlayerSongControls";
import { PlayerTrackInfo } from "./ui/PlayerTrackInfo";

type Props = {
  handleNonAuthAction: () => void;
  isEqualizerOn?: boolean;
};

export const Player = observer(({
  handleNonAuthAction,
  isEqualizerOn = false
}: Props) => {
  const { appMediaStore, authorizationProcess } = AppStore.getShared();
  const {
    nowPlayingStore,
    stationSwitchStore,
    favouriteStore,
    playerControlStore,
    songHistoryStore
  } = appMediaStore;

  playerControlStore.audioRef = useRef<HTMLAudioElement>(null);
  playerControlStore.svgRef = useRef<HTMLElement>(null);
  const timeLineRef = useRef<HTMLElement>(null);
  const { authStore } = authorizationProcess;
  const { isAuth } = authStore;
  const isMobile = useScreenMode();
  const volumeBarStore = useStoreFactory({
      createStore: () => new VolumeBarStore(),
  });

  const { currentStation, topTracks, myTracks } = stationSwitchStore;
  const isCustomStation = currentStation?.isCustom ?? false;

  const { nowPlayingData } = nowPlayingStore;
  const { likedSongs } = favouriteStore;
  const type = isCustomStation ? "short" : "full";

  if (!nowPlayingData) return <Fragment/>;
  const { song } = nowPlayingData;
  if (!song) return <Fragment />;

  const getCustomStationData = () => {
    switch (stationSwitchStore.listType) {
      case "personal":
        return myTracks;
      case "history":
        return songHistoryStore.songHistory;
      case "top":
        return topTracks
      default:
        return myTracks;
    }
  };

  const time = {
    elapsed: nowPlayingData.elapsed,
    duration: nowPlayingData.duration,
    remaining: nowPlayingData.remaining
  };

  return (
    <Fragment>
      {isEqualizerOn && <div className="equalizer-container">
        <Equalizer svgRef={playerControlStore.svgRef}/>
      </div>}
        <div className={`player-container player-container_${type}`}>
          {isCustomStation && <TimeLine time={time} timeLineRef={timeLineRef}/>}
          <div className="player">
            <div className="player__track-info">
              <PlayerTrackInfo song={song} />
            </div>
            <PlayerControls
              playerControlStore={playerControlStore}
              stationSwitchStore={stationSwitchStore}
              isMobile={isMobile}
              song={song}
              volumeBarStore={volumeBarStore}
              isCustomStation={isCustomStation}
              time={time}
              customStationData={getCustomStationData()}
            />
            <div className="player__section">
              <div className="player__volume-bar">
                <VolumeBar store={volumeBarStore} />
              </div>
              <div className="player__action-buttons">
                <PlayerSongControls
                  model={nowPlayingData}
                  likedSongs={likedSongs}
                  handleNonAuthAction={handleNonAuthAction}
                  isAuth={isAuth}
                />
              </div>
            </div>
          </div>
        </div>
    </Fragment>
  );
});
