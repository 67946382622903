import './Profile.css';

import { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "app";
import { RmdLogo } from "shared/ui/RmdLogo";
import { HeaderAccount } from "widgets/HeaderAccount";
import { Tabs } from 'widgets/Tabs';

import { RmdPage } from '../RmdPage';

export const Profile = observer(() => {
  const appStore = AppStore.getShared();
  const { authorizationProcess: { authStore, ...rest } } = AppStore.getShared();

  return (
    <Fragment>
      <RmdPage
        headerLeft={""}
        headerMiddle={<RmdLogo />}
        headerRight={
          <HeaderAccount authProcess={appStore.authorizationProcess} />
        }
        body={
          <main className="profile-page">
            <Tabs />
          </main>
        }
      />
    </Fragment>
  );
});