import './Home.css';

import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useScreenMode } from "app";
import { AppStore } from "app/model/AppStore";
import { ModalActionStore } from "features/PlayerControl/store/ModalActionStore";
import { HeaderAccount } from 'widgets/HeaderAccount';
import { SidebarStore } from "widgets/Sidebar/store/SidebarStore";
import { Sidebar, SidebarBtn } from "widgets/Sidebar";
import { Player } from "widgets/Player";
import { CustomStationModel, StationModel } from "entities/Station";
import { StationSwitch } from 'features/SwitchStation';
import { runPlayAfterSwitch } from "features/PlayerControl";
import { NotificationList } from "features/Notification";
import { useStoreFactory } from "shared/hook/useStoreFactory";
import { RmdLogo } from "shared/ui/RmdLogo";
import { BACKEND_URL, MAIN_PATH, SEO_PATH } from "shared/config/backendPaths";

import { RmdPage } from '../RmdPage';


export const Home = observer(() => {
  const [metaData, setMetaData] = useState([]);
  const isMobile = useScreenMode();
  const location = useLocation();
  const sidebarStore = useStoreFactory({
    createStore: () => new SidebarStore()
  });

  const { appMediaStore, authorizationProcess } = AppStore.getShared();
  const actionStore = ModalActionStore.getShared();
  const {
    stationSwitchStore,
    favouriteStore,
    songHistoryStore
  } = appMediaStore;
  const { sidebarOpened } = sidebarStore;
  const { authStore, notificationStore } = authorizationProcess;
  const { isAuth } = authStore;
  const { currentStation, topTracks, myTracks } = stationSwitchStore;
  const isCustomStation = currentStation?.isCustom ?? false;

  useEffect(() => {
    if (location.pathname === "/") {
      const getSeo = async () => {
        try {
          const response= await axios.get(BACKEND_URL + MAIN_PATH + SEO_PATH + "?page=main");
          setMetaData(response.data);
        } catch (e) {
          console.error(e);
        }
      }

      getSeo();
    }
  }, []);

  const handleNonAuthAction = () => authStore.openForm();

  return (
    <Fragment>
      <Helmet>
        <title>Random Minimal Deep</title>
        {metaData.map(({ name, id, content }) => <meta key={id} name={name} content={content}/>)}
      </Helmet>
      <RmdPage
        headerLeft={""}
        headerMiddle={<RmdLogo/>}
        headerRight={
          <HeaderAccount authProcess={authorizationProcess} />
        }
        body={
          <main className="home">
            <StationSwitch
              isAuth={isAuth}
              actionAfterSwitch={(
                model: StationModel | CustomStationModel,
                currentStation: StationModel | CustomStationModel | null
              ) => runPlayAfterSwitch(model, currentStation)}
            />
            <SidebarBtn
              action={isAuth || stationSwitchStore.currentStation?.icon === "deep" || stationSwitchStore.currentStation?.icon === "dnb"
                ? () => {
                  sidebarStore.openSidebar();
                  actionStore.openForm();
                  favouriteStore.getLiked();
                }
                : handleNonAuthAction
              }
              isVisible={!isMobile}
              isAuth={isAuth}
            />
            <Sidebar
              sidebarState={sidebarOpened}
              onSidebarClose={() => {
                sidebarStore.closeSidebar();
                actionStore.closeForm();
              }}
              topTracks={isCustomStation ? myTracks : topTracks}
              historyTracks={songHistoryStore.songHistory}
              likedSongs={favouriteStore.likedSongs}
              handleNonAuthAction={handleNonAuthAction}
              isAuth={isAuth}
              isCustomStation={isCustomStation}
            />
            <NotificationList store={notificationStore}/>
          </main>
        }
        footer={
          <Player
            handleNonAuthAction={handleNonAuthAction}
            isEqualizerOn
          />
        }
      />
      <Outlet/>
    </Fragment>
  );
});