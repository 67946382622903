import { AdminSocketStore } from "features/AdminSocketStation";
import { PlayerControlStore } from "features/PlayerControl";
import { StationSwitchStore } from "features/SwitchStation";
import { SongHistoryStore } from "features/SongHistory";
import { SongHistoryModel } from "entities/SongHistory";
import { NowPlayingModel } from "entities/NowPlaying";
import { NowPlayingStore } from "entities/NowPlaying";
import { FavouriteStore } from "entities/Favourite";
import { ISongHistoryBackendModel, INowPlayingBackendModel } from "entities/Song/types";
import { StationCodeType } from "features/AdminSocketStation/types";
import { ISongHistoryConfig } from "entities/SongHistory/types";

/**
 * @AppMediaStore - низкоуровневый стор, связывающий процессы
 * переключения станций, воспроизведение и переключение треков, лайки треков
 */
export class AppMediaStore {
    private static shared: AppMediaStore | null;

    public stationSwitchStore: StationSwitchStore = StationSwitchStore.getShared();

    public adminSocketStore: AdminSocketStore = new AdminSocketStore();

    public songHistoryStore: SongHistoryStore = new SongHistoryStore();

    public nowPlayingStore: NowPlayingStore = new NowPlayingStore();

    public favouriteStore: FavouriteStore = FavouriteStore.getShared();

    public playerControlStore: PlayerControlStore = PlayerControlStore.getShared();

    constructor() {
        this.stationSwitchStore.on("initialFetch", async () => {
            const stationCode = this.stationSwitchStore
                .currentStation?.wssCode as StationCodeType ?? "deep";
            await this.adminSocketStore.getInitialData(stationCode);
            await this.songHistoryStore.fetchSongHistory(stationCode);
            this.adminSocketStore.createSocket();
        });

        this.stationSwitchStore.on("stationChanged", async (wssCode) => {
            const stationCode = this.stationSwitchStore
                .currentStation?.wssCode as StationCodeType ?? "deep";
            await this.adminSocketStore.getInitialData(stationCode);
            await this.songHistoryStore.fetchSongHistory(stationCode);
            await this.stationSwitchStore.getTopTracks();
            this.adminSocketStore.emit("socketReInit", wssCode);
        });

        this.adminSocketStore.on("dataReceived", (data: any) => {
            /** Sidebar */
            const topStationTracks = data.song_history;
            const topTracks = topStationTracks
                .map((item: ISongHistoryBackendModel) => new SongHistoryModel(item as ISongHistoryConfig));
            this.songHistoryStore.setTopTracks(topTracks);


            /** Player */
            const nowPlaying: INowPlayingBackendModel = data.now_playing;
            const dataNowPlaying: NowPlayingModel = new NowPlayingModel(nowPlaying);
            if (this.stationSwitchStore.currentStation?.name !== "personal") {
              this.nowPlayingStore.setNowPlayingData(dataNowPlaying);
            }
        });

        this.favouriteStore.on("songHasBeenLiked", () => {
            this.stationSwitchStore.getMyTracks();
        })
    }

    static getShared(): AppMediaStore {
        if (this.shared) {
            return this.shared;
        }

        this.shared = new AppMediaStore();
        return this.shared;
    }
}