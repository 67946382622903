import "./PlayerTrackInfo.css";

import { observer } from "mobx-react-lite";

export const PlayerTrackInfo = observer(({song}: any) => (
  <div className="player-track-info">
    <div className="player-track-info__cover">
      <img src={song.art} alt="track cover" />
    </div>

    <div className="player-track-info__name">
      <p className="player-track-info__artist">{song.artist}</p>
      <p className="player-track-info__song">{song.title}</p>
    </div>
  </div>
));
